import React from 'react';
import './App.css';
import {fabric} from 'fabric';
import DesignCanvas from './Components/DesignCanvas';
import 'fabric-customise-controls';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import {Grid, Slide, Box, Container, Link} from '@material-ui/core';
import FirstPage from './LandingPage';
import Exemple from './Exemple';
import Despre from './DespreNoi';
import LoginPage from './LoginPage';
import Profile from './Profile';
import Checkout from './Checkout';
import {
  BrowserRouter as ReactRouter,
  Switch as RouterSwitch,
  Route,
  Link as RouterLink,
  Redirect,
} from 'react-router-dom';
import Axios from 'axios';
import {apiURL} from './Util';

const styles = theme => ({
  paper: {
    padding: '5px',
    textAlign: 'center',
    boxSizing: 'border-box',
    margin: '1px',
    filter: `grayscale(100%)`,
    '&:hover': {
      transition: '0.3s',
      boxShadow: '0 0 10px rgba(81, 203, 238, 1)',
      filter: `grayscale(0%)`,
    },
  },
});

const maxLengthDescriptionInSelect = 100;

function FormDialog(props) {
  const [open, setOpen] = React.useState(true);

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        style={{
          background: `linear-gradient(to right, #00b09b, #96c93d)`,
          'user-select': 'none',
        }}
        maxWidth="lg"
        fullWidth={true}
        // fullScreen={true}
        disableBackdropClick={true}
        transitionDuration={{enter: 500}}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Slide}
      >
        <DialogTitle id="form-dialog-title">Alege un produs</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <ul className="list-group">
                {props.objects.map((object, index) => (
                  <RouterLink to={'/configurator/select/' + index}>
                    <li className="list-group-item product-select">
                      <div className="media align-items-lg-center flex-column flex-lg-row p-3">
                        <div className="media-body order-2 order-lg-1">
                          <h5 className="mt-0 font-weight-bold mb-2">
                            {object.label}
                          </h5>
                          <p className="font-italic text-muted mb-0 small">
                            {object.description.substring(
                              0,
                              maxLengthDescriptionInSelect
                            )}
                            ...
                          </p>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <h6 className="font-weight-bold my-2">
                              {object.pricing} Lei
                            </h6>
                            <ul className="list-inline small">
                              <li className="list-inline-item m-0">
                                <i className="fa fa-star text-success"></i>
                              </li>
                              <li className="list-inline-item m-0">
                                <i className="fa fa-star text-success"></i>
                              </li>
                              <li className="list-inline-item m-0">
                                <i className="fa fa-star text-success"></i>
                              </li>
                              <li className="list-inline-item m-0">
                                <i className="fa fa-star text-success"></i>
                              </li>
                              <li className="list-inline-item m-0">
                                <i className="fa fa-star text-success"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <img
                          src={object.sides[0].complete}
                          alt="Generic placeholder image"
                          width="200"
                          className="ml-lg-5 order-1 order-lg-4"
                        />
                        {object.sides.length >= 2 && (
                          <img
                            src={object.sides[1].complete}
                            alt="Generic placeholder image"
                            width="200"
                            className="ml-lg-5 order-1 order-lg-4"
                          />
                        )}
                      </div>
                    </li>
                  </RouterLink>
                ))}
              </ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </div>
  );
}

const LoadingPage = () => {
  return (
    <>
      <div className="bg_load"></div>
      <div className="wrapper">
        <div className="inner">
          <span>L</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
        </div>
      </div>
    </>
  );
};

const StyleFormDialog = withStyles(styles)(FormDialog);

class Configurator extends React.Component {
  state = {
    inexistent: false,
    loading: true,
    selectedObject: -1,
    objectToSend: {
      elementsOnCanvas: [[], []],
      selectedSide: 0,
    },
    configObjects: [
  /*    {
        label: 'Sneakers',
        description: 'Sneakers description...',
        pricing: 260,
        centerObject: {
          left: 150,
          top: 100,
        },
        imageSize: {
          width: 654,
          height: 350,
        },
        sides: [
          {
            label: 'Exterior side',
            complete:
              'https://i.ibb.co/xj9c3kZ/Rally-White-Pink-Tony-Bianco-Casual-Default-1.jpg',
            back_img:
              'https://i.ibb.co/WsvcqHg/Rally-White-Pink-Tony-Bianco-Casual-Default-1-5.png',
            mask_img:
              'https://i.ibb.co/nkSmd0J/Rally-White-Pink-Tony-Bianco-Casual-Default-1-4.png',
            elementsOnCanvas: [],
          },
          {
            label: 'Interior side',
            complete:
              'https://i.ibb.co/cYNhyk2/Rally-White-Pink-Tony-Bianco-Casual-Default-1-1.jpg',
            mask_img:
              'https://i.ibb.co/C7hdPLK/Rally-White-Pink-Tony-Bianco-Casual-Default-1-4-1.png',
            back_img:
              'https://i.ibb.co/jTMqsjQ/Rally-White-Pink-Tony-Bianco-Casual-Default-1-5-1.png',
            elementsOnCanvas: [],
          },
        ],
        back_img:
          'https://i.ibb.co/WsvcqHg/Rally-White-Pink-Tony-Bianco-Casual-Default-1-5.png',
        mask_img:
          'https://i.ibb.co/nkSmd0J/Rally-White-Pink-Tony-Bianco-Casual-Default-1-4.png',
      },

      {
        label: 'Backpack',
        description:
          'Rucsac modern din piele naturala cu fața imprimată. Pe tot perimetrul deschiderii se închide cu fermoar care e fin la atingere, nu agața și e foarte rezistent.\
        Pe interior are 2 buzunare mici și 1 mare. Captușeala rezistenta, nu se destramă. Capacitate medie, cu dimensiunile față 32×26 înălțime și 12 cm adâncime jos.\
       Spate 28, înălțime 29 si adâncimea în partea de su 9.5 cm. Accesoriile folosite sunt italiene.',
        pricing: 300,
        centerObject: {
          left: 300,
          top: 300,
        },
        imageSize: {
          width: 1000,
          height: 600,
        },
        sides: [
          {
            label: 'Back-side',
            complete: 'https://i.ibb.co/FWr68yM/15804448-1-2.png',
            back_img: 'https://i.ibb.co/cyDtFLW/15804448-1-4.png',
            mask_img: 'https://i.ibb.co/cwTqXvT/15804448-1-1-1.png',
            elementsOnCanvas: [],
          },
        ],
      },

      {
        label: 'Boots',
        description: 'Boots description...',
        pricing: 400,
        centerObject: {
          left: 400,
          top: 300,
        },
        imageSize: {
          width: 1200,
          height: 1200,
        },
        sides: [
          {
            label: 'Left side',
            complete: 'https://i.ibb.co/cJhd554/Untitled-1.jpg',
            back_img: 'https://i.ibb.co/Fk1nJSW/background.png',
            mask_img: 'https://i.ibb.co/6wGnSFJ/bask.png',
            elementsOnCanvas: [],
          },
        ],
      },
*/
		{
        label: 'Cake',
        description: 'Cake customizer...',
        pricing: 400,
        centerObject: {
          left: 400,
          top: 300,
        },
        imageSize: {
          width: 600,
          height: 400,
        },
        sides: [
          {
            label: 'Top view',
            complete: 'https://i.ibb.co/7GtbHky/cake-front-mask-copy.png',
            back_img: 'https://i.ibb.co/HgVK0Rx/cake-fr2ont-mask.png',
            mask_img: 'https://i.ibb.co/sCgN0Gp/cake-front-mask4.png',
            elementsOnCanvas: [],
          },
        ],
      },

    ],
  };

  componentWillUpdate(nextProps) {
    if (
      (nextProps.match.params.id && this.props.match.params.number) ||
      (nextProps.match.params.id !== undefined &&
        this.props.match.params.id.toString() !=
          nextProps.match.params.id.toString())
    ) {
      this.setState({
        loading: true,
      });
      Axios.get(apiURL + '/user/active_items', {
        headers: {
          token: window.localStorage.getItem('token'),
        },
      })
        .then(response => {
          if (
            response.data.active_items.some(
              item => item.productId == this.props.match.params.id
            )
          ) {
            this.setState({
              selectedObject: response.data.active_items.find(
                item => item.productId == this.props.match.params.id
              ).selectedIndex,
              toRender: response.data.active_items.find(
                item => item.productId == this.props.match.params.id
              ),
              loading: false,
            });
          } else {
            this.setState({
              inexistent: true,
            });
          }
        })
        .catch(err => {
          this.props.history.push('/login');
        });
    } else {
    }
  }

  componentWillReceiveProps(nextProps) {}

  componentWillMount() {
    if (this.props.match.params.id) {
      Axios.get(apiURL + '/user/active_items', {
        headers: {
          token: window.localStorage.getItem('token'),
        },
      })
        .then(response => {
          if (
            response.data.active_items.some(
              item => item.productId == this.props.match.params.id
            )
          ) {
            this.setState({
              selectedObject: response.data.active_items.find(
                item => item.productId == this.props.match.params.id
              ).selectedIndex,
              toRender: response.data.active_items.find(
                item => item.productId == this.props.match.params.id
              ),
              loading: false,
            });
          } else {
            this.setState({
              inexistent: true,
            });
          }
        })
        .catch(err => {
          this.props.history.push('/login');
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  setSelected = index => {
    this.setState({
      selectedObject: index,
      productId: null,
    });
  };

  toInitialPage = () => {
    this.setState({
      selectedObject: -1,
      objectToSend: {
        elementsOnCanvas: [[], []],
        selectedSide: 0,
      },
    });
  };

  updateCanvas = data => {
    this.setState({
      objectToSend: data,
      selectedObject: data.selectedIndex,
      selectedSide: 0,
      productId: data.productId,
    });
  };

  componentDidMount() {
    new fabric.Canvas('c');

    fabric.Canvas.prototype.customiseControls({
      mtr: {
        action: 'rotate',
        cursor: 'default',
      },
      br: {
        action: 'scale',
        cursor: 'nwse-resize',
      },
      bl: {
        action: 'remove',
        cursor: 'default',
      },
      mb: {
        action: 'drag',
        cursor: 'move',
      },
      mt: {
        action: 'drag',
        cursor: 'move',
      },
      tr: {
        action: 'drag',
        cursor: 'move',
      },
      ml: {
        action: 'drag',
        cursor: 'move',
      },
      tl: {
        action: 'drag',
        cursor: 'move',
      },
      mr: {
        action: 'drag',
        cursor: 'move',
      },
    });
  }

  changeProductId = productId => {
    console.log(this.props);
    this.setState({
      selectedIndex: -1,
    });
    this.props.history.push('/configurator/item/' + productId);
  };

  render() {
    console.log(this.props.match);
    if (this.state.inexistent) {
      return <Redirect to="/profile" />;
    }
    if (
      this.state.loading ||
      (this.state.toRender === undefined && this.props.match.params.id)
    )
      return <LoadingPage />;
    if (this.state.selectedObject === -1 && this.props.match.params.number) {
      this.setState({
        selectedObject: parseInt(this.props.match.params.number),
      });
    }
    if (this.state.selectedObject === -1) {
      return (
        <StyleFormDialog
          classes={styles}
          objects={this.state.configObjects}
          setSelected={this.setSelected}
        />
      );
    }
    var selected = this.state.configObjects[this.state.selectedObject];
    return (
      <>
        <DesignCanvas
          productId={this.state.productId || null}
          objectToSend={
            this.props.match.params.id
              ? this.state.toRender
              : this.state.objectToSend
          }
          changeProductId={this.changeProductId}
          toInitialPage={this.toInitialPage}
          updateCanvas={this.updateCanvas}
          selectedIndex={
            this.props.match.params.number
              ? parseInt(this.props.match.params.number)
              : this.props.match.params.id != undefined
              ? this.state.toRender.selectedIndex
              : this.state.selectedObject
          }
          selected={selected}
        />
      </>
    );
  }
}

const EntireApp = () => {
  //Aceasta zona va fi eliminata
  const [confirmed, updateConfirmed] = React.useState(false);

  if (confirmed === false && window.localStorage.getItem('password2') === null) {
    var parola = window.prompt(
      'Site-ul este inchis pentru testele finale de securitate, ne cerem scuze. Revenim in curand!'
    );
    if (parola === '123qwe') {
      window.localStorage.setItem('password2', true);
      updateConfirmed(true);
    } else {
      return <h1>Continut indisponibil</h1>;
    }
  }
  //Aceasta zona va fi eliminata
  return (
    <ReactRouter>
      <RouterSwitch>
        <Route exact path="/" component={/*FirstPage*/Configurator} />
        <Route
          exact
          path="/configurator"
          component={() => <Redirect to="/configurator/select" />}
        />
        <Route exact path="/configurator/select" component={Configurator} />
        <Route
          exact
          path="/configurator/select/:number"
          component={Configurator}
        />
        <Route exact path="/configurator/item/:id" component={Configurator} />
		<Route path="*" component={Configurator} />
        //<Route path="/login" component={LoginPage} />
        //<Route path="/about" component={Despre} />
        //<Route path="/samples" component={Exemple} />
        //<Route path="/profile" component={Profile} />
        //<Route path="/checkout" component={Checkout} />
      </RouterSwitch>
    </ReactRouter>
  );
};

export default EntireApp;
