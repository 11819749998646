import React from 'react';
import PropTypes from 'prop-types';
import Image from './Image';
import Text from './Text';
import Axios from 'axios';
import BottomBar from './BottomBar';
import { generateID } from '../Util';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copy from '@material-ui/icons/FileCopy';
import Select from '@material-ui/core/Select';
import { Link, Redirect } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Delete from '@material-ui/icons/Delete';
import Replay from '@material-ui/icons/Replay';
import TextFormat from '@material-ui/icons/TextFormat';
import CloudUpload from '@material-ui/icons/CloudUpload';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import ImageIcon from '@material-ui/icons/Image';
import SaveIcon from '@material-ui/icons/Save';
import Left from '@material-ui/icons/KeyboardBackspace';
import { Container, Box } from '@material-ui/core';
import scrollToComponent from 'react-scroll-to-component';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import Draggable from 'react-draggable';
import Img1 from '../design_images/1.png';
import Img2 from '../design_images/2.png';
import Img3 from '../design_images/3.png';
import Img4 from '../design_images/4.png';
import Img5 from '../design_images/5.png';
import Img6 from '../design_images/6.png';
import Img7 from '../design_images/7.png';
import Img8 from '../design_images/8.png';
import Img9 from '../design_images/9.png';

import Img27 from '../design_images/27.png';
import Img28 from '../design_images/28.png';
import Img29 from '../design_images/29.png';
import Img30 from '../design_images/30.png';
import Img31 from '../design_images/31.png';
import Img32 from '../design_images/32.png';
//import Img33 from '../design_images/33.png';
import Img34 from '../design_images/34.png';



import AddIcon from '@material-ui/icons/Add';
import { apiURL } from '../Util';

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const fabric = window.fabric;

const TextAddElement = props => {
  const [textInfo, updateTextInfo] = React.useState('');
  return (
    <FormControl fullWidth>
      <TextField
        placeholder="Write something..."
        onChange={e => {
          updateTextInfo(e.target.value);
        }}
        value={textInfo}
      />
      <Button
        fullWidth
        onClick={() => {
          props.addText(textInfo);
          updateTextInfo('');
        }}
        variant="contained"
        // color="primary"
        style={{ backgroundColor: 'steelblue', color: 'white' }}
      >
        Add text
      </Button>
    </FormControl>
  );
};

class PersonalizeTabs extends React.PureComponent {
  state = { activeIndex: 0 };

  static containerStyle = {
    maxHeight: '400px',
    overflow: 'auto',
    // boxShadow: "inset 0 -4px 10px -10px #000000"
  };

  handleChange = (_, activeIndex) => this.setState({ activeIndex });
  render() {
    const { activeIndex } = this.state;
    return (
      <Box container>
        <Grid item lg={12}>
          <VerticalTabs
            centered
            scrollButtons="on"
            variant="fullWidth"
            value={activeIndex}
            onChange={this.handleChange}
          >
            <MyTab icon={<ImageIcon />} label="Images" />
            <MyTab icon={<TextFormat />} label="Add text" />
            <MyTab icon={<CloudUpload />} label="Upload" />
          </VerticalTabs>
        </Grid>
        <Grid item lg={12}>
          {activeIndex === 0 && (
            <Box style={PersonalizeTabs.containerStyle}>
              <TabContainer>
                {this.props.elements.map((element, index) => {
                  return (
                    <Grid
                      item
                      xs={2}
                      xl={2}
                      lg={2}
                      md={2}
                      sm={2}
                      className="hreni"
                      style={{ display: 'inline-block' }}
                    >
                      <CardMedia
                        component="img"
                        draggable={true}
                        onDragStart={e => {
                          e.dataTransfer.effectAllowed = 'copy';
                          e.dataTransfer.setData('eid', element.elementId);
                        }}
                        onClick={() => {
                          scrollToComponent(this.props.canvasRef, {
                            offset: 0,
                            align: 'middle',
                            duration: 500,
                            ease: 'inCirc',
                          });

                          this.props.addImageOnCanvas(element.elementId);
                        }}
                        image={element.url}
                        // width="100%"
                        alt="not found"
                      />
                    </Grid>
                  );
                })}
              </TabContainer>
            </Box>
          )}
          {activeIndex === 2 && (
            <TabContainer>
              <UploadImage addElementDynamic={this.props.addElementDynamic} />
            </TabContainer>
          )}
          {activeIndex === 1 && (
            <TabContainer>
              <TextAddElement addText={this.props.addTextOnCanvas} />
            </TabContainer>
          )}
        </Grid>
      </Box>
    );
  }
}

const LeftTabs = withStyles(theme => ({
  containerStyle: {
    overflow: 'auto',
  },
}))(PersonalizeTabs);

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'row',
  },
  root: {
    overflow: 'inherit',
    outline: 'none',
  },
}))(Tabs);

const MyTab = withStyles(theme => ({
  root: {
    outline: 'none !important',
    minWidth: '60px',
  },
  selected: {
    color: 'tomato',
    outline: 'none',
    // borderBottom: "2px solid #7ba1d7"
  },
}))(Tab);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

function DraggableDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open form dialog
      </Button>
      <Dialog
        className={{
          root: {
            disableBackdropClick: false,
          },
        }}
        disableBackdropClick={true}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={false}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Subscribe
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ItemPallete = props => {
  const titleStyle = {
    margin: 'auto',
    display: 'block',
    textAlign: 'center',
    borderBottom: '2px solid #7ba1d7',
    borderRadius: 0,
    marginLeft: '8px',
    marginRight: '8px',
    color: 'steelblue',
  };

  return (
    <Grid>
      <Typography variant="h6" style={titleStyle}>
        DESIGN ITEMS
      </Typography>
      <LeftTabs {...props} />
    </Grid>
  );
};

const UploadImage = props => {
  const [uploadActive, updateUploadActive] = React.useState(false);
  const [success, updateSuccess] = React.useState(false);
  return (
    <Container>
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="inputGroupFile01"
          aria-describedby="inputGroupFileAddon01"
          accept="image/*"
          style={{ backgroundColor: 'steelblue', width: '100%' }}
          onChange={event => {
            updateUploadActive(true);
            var FR = new FileReader();
            FR.addEventListener('load', function (e) {
              props.addElementDynamic(e.target.result);
              updateUploadActive(false);
              updateSuccess(true);
              setTimeout(() => updateSuccess(false), 2800);
            });
            FR.readAsDataURL(event.target.files[0]);
          }}
        />
        {uploadActive && (
          <>
            Loading, please wait for upload process...
            <LoadingIcon />
          </>
        )}
        {success && (
          <Typography>
            Upload with succes, check <b>"Ready for use"</b> zone
          </Typography>
        )}
        <label className="custom-file-label" htmlFor="inputGroupFile01">
          Choose file
        </label>
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  spinner: {
    margin: 'auto',
    verticalAlign: 'middle',
  },
  root: {
    padding: theme.spacing(3, 2),
  },
  grid: {
    padding: theme.spacing(3, 2),
  },
  button: {
    display: 'block',
    margin: '5px auto',
    height: theme.spacing(4),
    verticalAlign: 'bottom',
  },
  inserted: {
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(3, 2),
    height: '100%',
    maxHeight: theme.spacing(30),
    maxWidth: theme.spacing(20),
    borderRadius: theme.spacing(2),
    boxShadow: '1px 1px 4px black',
    margin: 'auto',
  },
  delete: {
    display: 'block',
    margin: '5px auto',
    height: theme.spacing(4),
    verticalAlign: 'bottom',
  },
  img: {
    width: '90%',
    margin: 'auto',
    display: 'block',
    maxHeight: theme.spacing(10),
    maxWidth: theme.spacing(13),
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
}));

const useClassStyles = theme => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  mainPaper: {
    boxShadow: '0 0 5px rgba(81, 203, 238, 1)',
    padding: theme.spacing(3, 2),
  },
  mainContainer: {
    marginTop: theme.spacing(3),
  },
  gridItemPallete: {
    padding: theme.spacing(3, 2),
    maxHeight: '400px',
  },
  title: {
    textAlign: 'center',
    borderBottom: '2px solid #7ba1d7',
    borderRadius: 0,
    display: 'block',
    margin: 'auto',
    marginBottom: '20px',
    color: 'steelblue',
  },
  subtitle: {
    textAlign: 'center',
    borderRadius: 0,
  },
  selectedSide: {
    border: '1px solid #7ba1d7',
    borderRadius: '5px',
  },
  canvasContainer: {
    margin: 'auto',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(3, 2),
  },
});

const LoadingIcon = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.spinner} color="primary" />;
};

const SimpleImage = props => {
  const [image, updateImage] = React.useState(false);
  React.useEffect(() => {
    updateImage(false);
  }, [props.url]);
  return (
    <>
      {image || <LoadingIcon />}
      <CardMedia
        component="img"
        alt="can't load resource"
        {...props}
        style={{
          display: image ? 'inline-block' : 'none',
        }}
        onLoad={() => updateImage(true)}
      />
    </>
  );
};

const InsertedItem = props => {
  const [image, updateImage] = React.useState(false);
  const classes = useStyles();

  return (
    <Grid
      item
      sm={3}
      lg={3}
      xl={3}
      className={classes.grid}
      style={{ position: 'relative' }}
    >
      <Paper
        className={classes.inserted}
        style={{
          backgroundColor: props.selected ? '#ebf1fa' : 'white',
          position: 'relative',
        }}
      >
        {props.canvas.getObjects().some(e => e.id === props.id) ? (
          <Grid container style={{ display: 'flex', flex: 1 }} spacing={1}>
            <img
              onClick={() => {
                props.canvas.setActiveObject(
                  props.canvas.getObjects().find(e => e.id === props.id)
                );
                props.canvas.renderAll();
                props.changeState();
              }}
              alt="couldn't be loaded"
              id={props.index}
              onLoad={() => updateImage(true)}
              className={classes.img}
              style={{ display: 'block', margin: 'auto' }}
              src={props.url}
            // style={{ "display": image ? "block" : "none" }}
            />
            {!image && (
              <CircularProgress className={classes.spinner} color="secondary" />
            )}
            <Tooltip title="Default state" placement="right">
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  props.recenterImage(props.id);
                }}
              >
                <Replay />
              </Button>
            </Tooltip>
            <Tooltip title="Duplicate item" placement="right">
              <Button
                className={classes.button}
                variant="contained"
                onClick={() => {
                  props.duplicateItem(props.id);
                }}
              >
                <Copy />
              </Button>
            </Tooltip>
            <Tooltip title="Delete" placement="right">
              <Button
                className={classes.delete}
                variant="contained"
                color="secondary"
                onClick={() => props.removeItem(props.id)}
              >
                <Delete />
              </Button>
            </Tooltip>
          </Grid>
        ) : (
            <>
              <CircularProgress className={classes.spinner} color="primary" />
              <h3>Loading...</h3>
            </>
          )}
      </Paper>
    </Grid>
  );
};

class SketchExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: this.props.color,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = e => {
    this.props.updateColor(e.hex);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: this.props.color,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.props.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const ElementControlPanel = props => {
  const [color, updateColor] = React.useState(
    props.element
      ? props.element.text
        ? props.element.fill
        : 'black'
      : 'black'
  );
  const [font, updateFont] = React.useState(0);

  const valuetext = value => {
    return `${value}°`;
  };

  React.useEffect(() => {
    if (props.element && props.element.text) {
      updateColor(props.element.fill);
    }
  }, [props.element]);

  const availableFonts = [
    'Sofia',
    'Aguafina Script',
    'Akronim',
    'Aladin',
    'Aldrich',
    'Alef',
    'Alegreya SC',
    'Alex Brush',
    'Alegreya SC',
    'Alegreya Sans',
    'Allan',
    'Allura',
    'Allerta Stencil',
    'Almendra Display',
    'Amatic SC',
    'Amita',
    'Annie Use Your Telescope',
    'Anonymous Pro',
    'Arbutus',
    'Architects Daughter',
    'Archivo Black',
    'Arizonia',
    'Asap Condensed',
    'Asset',
    'Astloch',
    'Audiowide',
    'Bad Script',
    'Bonbon',
  ];

  React.useEffect(() => {
    if (props.element) {
      props.element.fontFamily = availableFonts[font];
      props.element.set('fill', color);
      props.canvas.requestRenderAll();
    }
  });

  return (
    <>
      {/* Pallete for text element */
        props.element && props.element.text && (
          <Container>
            <Paper style={{ padding: '20px' }}>
              <Typography variant="h6">Choose font</Typography>
              <Select
                value={font}
                onChange={e => {
                  updateFont(e.target.value);
                }}
              >
                {availableFonts.map((x, index) => {
                  return (
                    <MenuItem style={{ fontFamily: x }} value={index}>
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>
              <Typography variant="h6">Select text colour</Typography>
              <SketchExample color={color} updateColor={updateColor} />
            </Paper>
          </Container>
        )}
    </>
  );
};

class DesignCanvas extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  };

  static defaultProps = {
    width: 600,
    height: 400,
  };
  canvasRef = null;

  state = {
    qrURL: "",
    lastWidth: -1,
    lastHeight: -1,
    isAuthenticated: false,
    authResolved: false,
    canvas: null,
    selectedSide: 0,
    elements: [
      /*{
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img1,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img2,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img3,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img4,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img5,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img6,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img7,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img8,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img9,
      },*/
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img27,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img28,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img29,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img30,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img31,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img32,
      },
      {
        elementId: generateID(),
        defaultScale: null,
        type: 'Image',
        url: Img34,
      },
    ],
    elementsOnCanvas: [[], []],
    activeEvenets: [
      'mouse:up:before',
      'selection:created',
      'object:scalling',
      'object:added',
    ],
  };

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateCanvasSize.bind(this),
      true
    );

    clearInterval(this.state.updateInterval);
  }

  updateCanvasSize() {
    if (document.getElementById('canvasParent') === null) {
      return;
    }
    const width = document.getElementById('canvasParent').clientWidth;
    this.state.canvas.setDimensions({
      width,
      height:
        (width / this.props.selected.imageSize.width) *
        this.props.selected.imageSize.height,
    });
    let selectedSide = this.state.selectedSide;
    if (selectedSide >= this.props.selected.sides.length) {
      selectedSide = this.props.selected.sides.length - 1;
      this.setState({
        selectedSide,
      });
    }
    this.state.canvas.setBackgroundImage(
      this.props.selected.sides[selectedSide].back_img,
      this.state.canvas.renderAll.bind(this.state.canvas),
      {
        crossOrigin: 'anonymous',
        scaleX: width / this.props.selected.imageSize.width,
        scaleY: width / this.props.selected.imageSize.width,
      }
    );
    //Apply overlay image, this image will have transparent zone for customizing item
    this.state.canvas.setOverlayImage(
      this.props.selected.sides[selectedSide].mask_img,
      this.state.canvas.renderAll.bind(this.state.canvas),
      {
        crossOrigin: 'anonymous',
        scaleX: width / this.props.selected.imageSize.width,
        scaleY: width / this.props.selected.imageSize.width,
      }
    );
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.objectToSend.elementsOnCanvas) !==
      JSON.stringify(this.state.elementsOnCanvas)
    ) {
      this.state.canvas.clear();
      this.setState({
        ...this.props.objectToSend,
        selectedSide: 0,
      });
    }
    this.updateCanvasSize();
  }

  componentDidMount() {
    this.setState({
      ...this.props.selectedSide,
      // updateInterval: setInterval(() => {
      //   this.changeState();
      // }, 1000)
    });
    const thisObj = this;
    //Generate initial canvas
    const canvas = new fabric.Canvas(this.c);
    //Apply controlls over foreground image
    canvas.controlsAboveOverlay = true;
    //Apply background image, zone where user can insert images
    window.canvas = canvas;
    //Apply change state on specific events
    this.state.activeEvenets.map(eventName =>
      canvas.on(eventName, this.changeState)
    );
    //Set instatiated canvas in the state
    this.setState({ canvas }, () => {
      this.updateCanvasSize();
    });
    //Set canvas constraints
    canvas.on('object:modified', event => {
      canvas.renderAll();
    });

    window.addEventListener('resize', this.updateCanvasSize.bind(this), true);

    canvas.on('object:removed', event => {
      this.setState(state => {
        let newCanvas = state.elementsOnCanvas[state.selectedSide].filter(
          e => e.id !== event.target.id
        );
        state.elementsOnCanvas[state.selectedSide] = newCanvas;
        return state;
      });
    });

    canvas.on('mouse:out', event => {
      if (event.target) {
        event.target.set({
          shadow: null,
        });
        canvas.renderAll();
      }
    });

    canvas.on('mouse:over', event => {
      if (event.target) {
        event.target.set({
          shadow: '2px 2px 10px black',
        });
        canvas.renderAll();
      }
    });

    canvas.on('object:moving', event => {
      if (!event.target._objects)
        canvas.getActiveObjects().map(object => {
          return true;
        });
      canvas.requestRenderAll();
    });
    //Remove action, apply function, should be changed in the future
    document.addEventListener('keydown', event => {
      if (
        (event.keyCode === 8 || event.keyCode === 46) &&
        canvas.getActiveObjects()[0] &&
        !canvas.getActiveObjects()[0].isEditing
      ) {
        this.removeSelectedItem();
      }
    });
    //Force render canvas
    setTimeout(() => this.state.canvas.renderAll(), 500);

    if (
      this.state.isAuthenticated === false &&
      window.localStorage.getItem('token')
    ) {
      Axios.post(
        apiURL + '/api/v1/auth/jwt',
        {},
        {
          headers: {
            token: window.localStorage.getItem('token'),
          },
        }
      )
        .then(data => {
          if (data.status === 200) {
            this.setState({
              isAuthenticated: true,
              user: JSON.parse(window.localStorage.getItem('user')),
              authResolved: true,
            });
          }
        })
        .catch(err => {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('user');
        });
    } else {
      this.setState({
        authResolved: true,
      });
    }
  }

  addElementDynamic = data => {
    //Upload image from computer
    this.setState({
      elements: [
        {
          url: data,
          type: 'Image',
          elementId: generateID(),
          defaultScale: 0.1,
          center: {
            left: this.state.canvas.getWidth() / 2,
            top: this.state.canvas.getHeight() / 2,
          },
        },
        ...this.state.elements,
      ],
    });
  };

  addTextOnCanvas = text => {
    scrollToComponent(this.c, {
      offset: 0,
      align: 'middle',
      duration: 500,
      ease: 'inCirc',
    });
    this.updateCanvasSize();
    this.setState(
      state => {
        state.elementsOnCanvas[state.selectedSide].push({
          type: 'Text',
          text,
          left: this.state.canvas.getWidth() / 2,
          top: this.state.canvas.getHeight() / 2,
          fontSize: 30,
          fontFamily: 'Sofia',
          fill: 'black',
          angle: 0,
          url: 'https://static.thenounproject.com/png/66759-200.png',
          id: generateID(),
          originX: 'center',
          originY: 'center',
          defaultScale: 1,
          scale: 1,
        });
        return state;
      },
      () => {
        this.changeState();
      }
    );
  };

  addImageOnCanvas = (id, x, y) => {
    this.updateCanvasSize();
    this.setState(state => {
      state.elementsOnCanvas[state.selectedSide].push({
        ...this.state.elements.find(element => element.elementId === id),
        center: {
          // left: this.state.canvas.getWidth() / 2 + 50,
          // top: this.state.canvas.getHeight() / 2 + 50
        },
        left: x
          ? window.pageXOffset + x - this.state.canvas._offset.left
          : this.state.canvas.getWidth() / 2 + 10,
        top: y
          ? window.pageYOffset + y - this.state.canvas._offset.top
          : this.state.canvas.getHeight() / 2 + 10,
        id: generateID() /* Exclusive ID for identification */,
      });
      return state;
    });
  };

  removeSelectedItem = () => {
    if (
      this.state &&
      this.state.canvas &&
      this.state.canvas.getActiveObjects().length !== 0 &&
      window.confirm('Remove selected item(s) items?')
    ) {
      var elementsOnCanvas = this.state.elementsOnCanvas[
        this.state.selectedSide
      ];
      this.state.canvas.getActiveObjects().forEach(obj => {
        elementsOnCanvas = elementsOnCanvas.filter(
          element => element.id !== obj.id
        );
        this.state.canvas.remove(obj);
      });
      this.setState(state => {
        state.elementsOnCanvas[this.state.selectedSide] = elementsOnCanvas;
      });
      this.state.canvas.discardActiveObject();
      this.state.canvas.requestRenderAll();
    }
  };

  duplicateItem = id => {
    this.setState(state => {
      //Determine element to duplicate
      const elementToDuplicate = this.state.elementsOnCanvas[
        this.state.selectedSide
      ].find(element => element.id === id);
      //Find element on canvas space
      const elementOnCanvasToDuplicate = state.canvas
        .getObjects()
        .find(element => element.id === id);
      //Buil new array of items in
      state.elementsOnCanvas[state.selectedSide].push({
        ...elementToDuplicate,
        id: generateID(),
        angle: elementOnCanvasToDuplicate.angle,
        left: elementOnCanvasToDuplicate.left + 20,
        top: elementOnCanvasToDuplicate.top + 20,
        scale: elementOnCanvasToDuplicate.scaleX,
        fontFamily: elementOnCanvasToDuplicate.fontFamily,
        fill: elementOnCanvasToDuplicate.fill,
      });

      // state.canvas.requestRenderAll();
      return state;
    });
  };

  recenterImage = id => {
    var selected = this.state.canvas
      .getObjects()
      .find(element => element.id === id);
    selected.set({
      angle: Image.defaultProps.initialRotateValue,
      left:
        this.state.canvas.getWidth() / 2 +
        (selected.width * selected.scaleX) / 2,
      top:
        this.state.canvas.getHeight() / 2 +
        (selected.hegith * selected.scaleX) / 2,
    });
    selected.scale(selected.defaultScale);
    selected.setCoords();
    this.state.canvas.renderAll();
  };

  changeState = () => {
    this.setState({ date: Date.now() });
  };

  removeItemFromCanvas = id => {
    this.state.canvas.getObjects().map(obj => {
      if (obj.id === id) {
        this.state.canvas.remove(obj);
      }
      return null;
    });
    this.setState(state => {
      state.elementsOnCanvas[state.selectedSide] = state.elementsOnCanvas[
        state.selectedSide
      ].filter(element => element.id !== id);
    });
    this.state.canvas.discardActiveObject().renderAll();
    this.changeState();
  };

  render() {
    return (
      <div style={{ 'user-select': 'none' }}>
        {/* <AppBar toInitialPage={this.props.toInitialPage} /> */}
        <Link
          style={{ zIndex: 1000, position: 'fixed', top: 3, left: 3 }}
          to="/profile"
        >
          <Tooltip title="Intorce-te la profil" aria-label="add">
            <Fab color="secondary" aria-label="add">
              <Left />
            </Fab>
          </Tooltip>
        </Link>
        <Link
          style={{ zIndex: 1000, position: 'fixed', top: 3, right: 3 }}
          to="/configurator/"
        >
          <Tooltip title="Adauga un produs nou" aria-label="add">
            <Fab color="secondary" aria-label="add">
              <AddIcon />
            </Fab>
          </Tooltip>
        </Link>
        <Container maxWidth className={this.props.classes.mainContainer}>
          <Paper className={this.props.classes.mainPaper}>
            <Grid container spacing={1}>
              <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                xl={4}
                className={this.props.classes.grid}
              >
                <ItemPallete
                  canvasRef={this.c}
                  canvas={this.state.canvas}
                  addTextOnCanvas={this.addTextOnCanvas}
                  addElementDynamic={this.addElementDynamic}
                  elements={this.state.elements}
                  addImageOnCanvas={this.addImageOnCanvas}
                />
                <ElementControlPanel
                  disabled={
                    this.state.canvas &&
                    this.state.canvas.getActiveObjects().length > 1
                  }
                  element={
                    (this.state.canvas &&
                      this.state.canvas.getActiveObjects()[0]) ||
                    undefined
                  }
                  canvas={this.state.canvas}
                />
              </Grid>

              <Grid
                item
                lg={7}
                md={7}
                sm={12}
                xs={12}
                xl={7}
                className={this.props.classes.grid}
              >
                <Typography variant="h6" className={this.props.classes.title}>
                  PRODUCT
                </Typography>
                <Box
                  onDragOver={e => {
                    e.preventDefault();
                  }}
                  onDrop={ev => {
                    ev.preventDefault();
                    let eid = ev.dataTransfer.getData('eid');
                    console.log(ev);
                    this.addImageOnCanvas(eid, ev.clientX, ev.clientY);
                  }}
                  id="canvasParent"
                  className={this.props.classes.canvasContainer}
                >
                  <canvas
                    onClick={this.changeState}
                    ref={c => (this.c = c)}
                    width="100%"
                  // className={this.props.classes.canvasContainer}
                  />

                  {this.state.canvas &&
                    this.state.elementsOnCanvas[this.state.selectedSide].map(
                      element => {
                        if (element.type === 'Image') {
                          return (
                            <Image
                              url={element.url}
                              top={
                                element.top ||
                                this.state.canvas.getHeight() / 2 - 50
                              }
                              left={
                                element.left ||
                                this.state.canvas.getWidth() / 2 - 30
                              }
                              id={element.id}
                              elementId={element.elementId}
                              canvas={this.state.canvas}
                              angle={element.angle || 0}
                              scale={element.scale || element.defaultScale}
                              defaultScale={element.defaultScale}
                            />
                          );
                        } else if (element.type === 'Text') {
                          return (
                            <Text {...element} canvas={this.state.canvas} />
                          );
                        }
                        return null;
                      }
                    )}
                </Box>
              </Grid>

              <Grid item md={1} lg={1} xl={1} sm={12}>
                <Grid>
                  <Typography
                    variant="h6"
                    className={this.props.classes.title}
                    style={{
                      visibility: 'hidden',
                      border: 'none',
                      margin: 0,
                    }}
                  >
                    {' '}
                    ....
                  </Typography>
                  <Grid container>
                    {this.props.selected.sides.map((side, index) => (
                      <Grid
                        className={
                          index === this.state.selectedSide &&
                          this.props.classes.selectedSide
                        }
                        onClick={() => {
                          if (index === this.state.selectedSide) {
                            return;
                          }
                          this.setState(
                            state => {
                              //Copy active elements from canvas
                              let elements = [...state.canvas.getObjects()];
                              //Clear canvas
                              this.state.canvas.clear();
                              //Clear previous elements from state
                              state.elementsOnCanvas[state.selectedSide] = [];
                              //Generate new array
                              state.elementsOnCanvas[
                                state.selectedSide
                              ] = elements.map(e => {
                                let objectCopy = {
                                  angle: e.angle || 0,
                                  top: e.top,
                                  left: e.left,
                                  url: e.url,
                                  type: e.type,
                                  id: e.id,
                                  elementId: e.elementId || null,
                                  scale: e.scaleX || e.defaultScale,
                                  scaleX: e.scaleX || e.defaultScale,
                                  scaleY: e.scaleY || e.defaultScale,
                                  defaultScale: e.defaultScale,
                                  fontSize: e.fontSize || undefined,
                                  fill: e.fill || undefined,
                                  text: e.text || undefined,
                                  originX: 'center',
                                  originY: 'center',
                                  fontFamily: e.fontFamily || 'Sofia',
                                };
                                //Send items into state store
                                return JSON.parse(JSON.stringify(objectCopy));
                              });
                              //Apply selected side
                              state.selectedSide = index;
                              //Return new state object
                              return state;
                            },
                            () => {
                              //Update canvas size
                              this.updateCanvasSize();
                              scrollToComponent(this.c, {
                                offset: 0,
                                align: 'middle',
                                duration: 500,
                                ease: 'inCirc',
                              });
                            }
                          );
                        }}
                        item
                        xs={6}
                        xl={12}
                        md={12}
                        lg={12}
                        direction="row"
                        justify="flex-start"
                      >
                        <Typography
                          variant="subtitle1"
                          className={this.props.classes.subtitle}
                        >
                          {side.label}
                        </Typography>
                        <Grid>
                          <CardMedia component="img" src={side.complete} />
                        </Grid>
                      </Grid>
                    ))}
                    {!this.state.isAuthenticated ? (
                      <Grid lg={12} xs={12} style={{ textAlign: 'center' }}>
                        <Link
                          to="/login"
                          onClick={() =>
                            window.localStorage.setItem(
                              'scope_redirect',
                              '/configurator/select/' + this.props.selectedIndex
                            )
                          }
                        >
                          <Button
                            id="add_to_cart"
                            fullWidth
                            variant="contained"
                          >
                            <AddShoppingCart
                              style={{ margin: 'auto' }}
                              fontSize="large"
                            />
                            <Typography variant="subtitle2">
                              Sign in to save
                            </Typography>
                          </Button>
                        </Link>
                      </Grid>
                    ) : this.props.objectToSend &&
                      !this.props.objectToSend.productId ? (
                          <>
                            <Grid lg={12} xs={12} style={{ textAlign: 'center' }}>
                              <Button
                                disabled={
                                  this.state &&
                                  this.state.canvas.getObjects().length === 0
                                }
                                onClick={() => {
                                  this.setState(
                                    state => {
                                      state.canvas.getObjects().map(object => {
                                        state.canvas.remove(object);
                                      });
                                      state.elementsOnCanvas[
                                        state.selectedSide
                                      ] = [];
                                      return state;
                                    },
                                    () => {
                                      this.state.canvas.clear();
                                      this.updateCanvasSize();
                                    }
                                  );
                                }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                              >
                                Clear
                          </Button>
                            </Grid>
                            <Grid lg={12} xs={12} style={{ textAlign: 'center' }}>
                              <Button
                                onClick={() => {
                                  this.setState(
                                    state => {
                                      //Copy active elements from canvas
                                      let elements = [...state.canvas.getObjects()];
                                      state.elementsOnCanvas[
                                        state.selectedSide
                                      ] = elements.map(e => {
                                        let objectCopy = {
                                          angle: e.angle || 0,
                                          top: e.top,
                                          left: e.left,
                                          url: e.url,
                                          type: e.type,
                                          id: e.id,
                                          elementId: e.elementId || null,
                                          scale: e.scaleX || e.defaultScale,
                                          scaleX: e.scaleX || e.defaultScale,
                                          scaleY: e.scaleY || e.defaultScale,
                                          defaultScale: e.defaultScale,
                                          fontSize: e.fontSize || undefined,
                                          fill: e.fill || undefined,
                                          text: e.text || undefined,
                                          originX: 'center',
                                          originY: 'center',
                                          fontFamily: e.fontFamily || 'Sofia',
                                        };
                                        //Send items into state store
                                        return JSON.parse(
                                          JSON.stringify(objectCopy)
                                        );
                                      });
                                      //Apply selected side
                                      //Return new state object
                                      return state;
                                    },
                                    () => {
                                      let productId = generateID();
                                      Axios.post(
                                        apiURL + '/user/add_item',
                                        {
                                          ...{
                                            productId,
                                            label: this.props.selected.label,
                                            vizualize: this.state.canvas.toDataURL(),
                                            elementsOnCanvas: this.state
                                              .elementsOnCanvas,
                                            selectedSide: this.state.selectedSide,
                                            selectedIndex: this.props.selectedIndex,
                                          },
                                        },
                                        {
                                          headers: {
                                            token: window.localStorage.getItem(
                                              'token'
                                            ),
                                          },
                                        }
                                      )
                                        .then(response => {
                                          this.props.changeProductId(productId);
                                        })
                                        .catch(err => { });
                                    }
                                  );
                                }}
                                id="add_to_cart"
                                fullWidth
                                variant="contained"
                              >
                                <AddShoppingCart
                                  style={{ margin: 'auto' }}
                                  fontSize="large"
                                />
                                <Typography variant="h6">Add</Typography>
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <Grid lg={12} xs={12} style={{ textAlign: 'center' }}>
                            <Button
                              onClick={() => {
                                this.setState(
                                  state => {
                                    //Copy active elements from canvas
                                    let elements = [...state.canvas.getObjects()];
                                    state.elementsOnCanvas[
                                      state.selectedSide
                                    ] = elements.map(e => {
                                      let objectCopy = {
                                        angle: e.angle || 0,
                                        top: e.top,
                                        left: e.left,
                                        url: e.url,
                                        type: e.type,
                                        id: e.id,
                                        elementId: e.elementId || null,
                                        scale: e.scaleX || e.defaultScale,
                                        scaleX: e.scaleX || e.defaultScale,
                                        scaleY: e.scaleY || e.defaultScale,
                                        defaultScale: e.defaultScale,
                                        fontSize: e.fontSize || undefined,
                                        fill: e.fill || undefined,
                                        text: e.text || undefined,
                                        originX: 'center',
                                        originY: 'center',
                                        fontFamily: e.fontFamily || 'Sofia',
                                      };
                                      //Send items into state store
                                      return JSON.parse(JSON.stringify(objectCopy));
                                    });
                                    //Apply selected side
                                    //Return new state object
                                    return state;
                                  },
                                  () => {
                                    let products = [];
                                    Axios.post(
                                      apiURL + '/user/update_item',
                                      {
                                        vizualize: this.state.canvas.toDataURL(),
                                        elementsOnCanvas: this.state
                                          .elementsOnCanvas,
                                        productId: this.props.objectToSend
                                          .productId,
                                      },
                                      {
                                        headers: {
                                          token: window.localStorage.getItem(
                                            'token'
                                          ),
                                        },
                                      }
                                    )
                                      .then(response => { })
                                      .catch(err => { });
                                  }
                                );
                              }}
                              color="secondary"
                              fullWidth
                              variant="contained"
                            >
                              <SaveIcon style={{ margin: 'auto' }} fontSize="large" />
                          SAVE
                        </Button>
                          </Grid>
                        )}
                    <Grid>
                      <Button
                        onClick={() => {
                          Axios.post("http://localhost:3000/save_image", {
                            image: this.state.canvas.toDataURL({left: 280 , top: 125, width: 214, height: 212})           })
                            .then(response => {
                              console.log(response.data);
                              this.setState({
                                qrURL: response.data.image
                              });
                            })
                            .catch(err => {
                              console.log(err);
                            })
                        }}
                      >View on mobile</Button>
                      {this.state.qrURL == "" ? <div>Aici va fi QR-ul</div> : <img src={this.state.qrURL} width="100px"/>}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {false && (
              <Grid
                item
                md={4}
                sm={12}
                lg={4}
                xl={3}
                className={this.props.classes.grid}
              >
                <Paper className={this.props.classes.paper}>
                  <Typography variant="h6" className={this.props.classes.title}>
                    Inserted elements
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    xl={12}
                    sx={12}
                    style={{ maxHeight: '400px', overflow: 'auto' }}
                  >
                    {this.state.elementsOnCanvas[this.state.selectedSide].map(
                      (element, index) => {
                        var selected = this.state.canvas
                          .getActiveObjects()
                          .some(active => element.id === active.id);
                        return (
                          <InsertedItem
                            changeState={this.changeState}
                            url={element.url}
                            id={element.id}
                            elementId={element.elementId || 'other'}
                            selected={selected}
                            recenterImage={this.recenterImage}
                            canvas={this.state.canvas}
                            duplicateItem={this.duplicateItem}
                            removeItem={() =>
                              this.removeItemFromCanvas(element.id)
                            }
                            index={index}
                          />
                        );
                      }
                    )}
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Paper>
        </Container>
        {this.state.isAuthenticated && (
          <BottomBar
            bla={3}
            updateCanvas={e => {
              this.setState({ selectedSide: 0 }, () =>
                this.props.updateCanvas(e)
              );
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(useClassStyles)(DesignCanvas);
