import React from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Axios from "axios";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import ShareIcon from "@material-ui/icons/Share";
import DeleteIcon from "@material-ui/icons/Delete";
import { apiURL } from "../Util";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    height: 50,
    zIndex: 10000
  },
  speedDial: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    transform: `scale(1)`,
    zIndex: 10000
  }
}));

const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
  { icon: <DeleteIcon />, name: "Delete" }
];

function SpeedDialTooltipOpen(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);
  const [changed, updateChanged] = React.useState(0);
  const [products, updateProducts] = React.useState([]);
  const [requested, updateRequested] = React.useState(false);
  if (!requested) {
    updateRequested(true);
    Axios.get(apiURL + "/user/active_items", {
      headers: {
        token: window.localStorage.getItem("token")
      }
    })
      .then(response => {
        updateProducts(response.data.active_items);
      })
      .catch(err => {
        console.log(err);
      });
  }

  window.addEventListener("storage", () => updateChanged(x => x + 1));

  const handleVisibility = () => {
    setOpen(false);
    setHidden(prevHidden => !prevHidden);
  };

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleOpen = () => {
    if (!hidden) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        hidden={hidden}
        icon={
          <StyledBadge badgeContent={products.length}>
            <ShoppingCart />
          </StyledBadge>
        }
        onBlur={handleClose}
        onClick={handleClick}
        onClose={handleClose}
        onFocus={handleOpen}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        open={open}
        ButtonProps={{
          size: "large",
          style: {
            transform: `scale(1.5)`
          },
          className: "cart_box"
        }}
      >
        <SpeedDialAction
          icon={<ShoppingBasket color="secondary" />}
          tooltipTitle="Checkout"
          tooltipOpen
          onClick={handleClick}
        />
        {products.map(product => (
          <SpeedDialAction
            icon={
              <Link to={"/configurator/item/" + product.productId}>
                <Avatar src={product.vizualize} />
              </Link>
            }
            tooltipTitle={product.label}
            tooltipOpen
          />
        ))}
      </SpeedDial>
    </div>
  );
}

const styles = {
  appBar: {
    top: "auto",
    bottom: 0
  },
  menuButton: {
    marginLeft: -12,
    marginRight: -12
  },
  actionButtons: {
    marginLeft: "auto"
  }
};

const StyledBadge = withStyles(theme => ({
  badge: {
    zIndex: 1,
    top: "-50%",
    right: "-90%",
    transform: "scale(1)",
    // right: -3,
    // The border color match the background color.
    border: `2px solid #7ba1d7`,
    backgroundColor: "gray",
    fontWeight: "bold"
  }
}))(Badge);

function BottomAppBar({ updateCanvas, classes }) {
  return <SpeedDialTooltipOpen updateCanvas={updateCanvas} />;
}

BottomAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BottomAppBar);
