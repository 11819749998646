import React from "react";
import Axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { apiURL } from "./Util";

const LoadingPage = () => {
  return (
    <>
      <div className="bg_load"></div>
      <div className="wrapper">
        <div className="inner">
          <span>L</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
        </div>
      </div>
    </>
  );
};

class Checkout extends React.Component {
  state = {
    isAuthenticated: false,
    authResolved: false,
    productsReady: false,
    products: []
  };

  pullData() {
    Axios.get(apiURL + "/user/active_items", {
      headers: {
        token: window.localStorage.getItem("token")
      }
    })
      .then(response => {
        console.log(response);
        this.setState({
          products: response.data.active_items,
          productsReady: true
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  componentDidMount() {
    if (
      this.state.isAuthenticated === false &&
      window.localStorage.getItem("token")
    ) {
      Axios.post(
        apiURL + "/api/v1/auth/jwt",
        {},
        {
          headers: {
            token: window.localStorage.getItem("token")
          }
        }
      )
        .then(data => {
          if (data.status === 200) {
            setTimeout(
              () =>
                this.setState({
                  isAuthenticated: true,
                  user: JSON.parse(window.localStorage.getItem("user")),
                  authResolved: true
                }),
              100
            );
            this.pullData();
          }
        })
        .catch(err => {
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("user");
        });
    } else {
      this.setState({
        authResolved: true
      });
    }
  }
  logOut = () => {
    this.setState({
      isAuthenticated: false
    });
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
  };

  render() {
    if (
      this.state.isAuthenticated === false &&
      this.state.authResolved === true
    ) {
      return <Redirect to="/profile" />;
    }
    if (
      this.state.authResolved === false ||
      this.state.productsReady === false
    ) {
      return <LoadingPage />;
    }
    return (
      <div>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              '\nbody {\n  font-family: Arial;\n  font-size: 17px;\n  padding: 8px;\n}\n\n* {\n  box-sizing: border-box;\n}\n\n.row {\n  display: -ms-flexbox; /* IE10 */\n  display: flex;\n  -ms-flex-wrap: wrap; /* IE10 */\n  flex-wrap: wrap;\n  margin: 0 -16px;\n}\n\n.col-25 {\n  -ms-flex: 25%; /* IE10 */\n  flex: 25%;\n}\n\n.col-50 {\n  -ms-flex: 50%; /* IE10 */\n  flex: 50%;\n}\n\n.col-75 {\n  -ms-flex: 75%; /* IE10 */\n  flex: 75%;\n}\n\n.col-25,\n.col-50,\n.col-75 {\n  padding: 0 16px;\n}\n\n.container {\n  background-color: #f2f2f2;\n  padding: 5px 20px 15px 20px;\n  border: 1px solid lightgrey;\n  border-radius: 3px;\n}\n\ninput[type=text] {\n  width: 100%;\n  margin-bottom: 20px;\n  padding: 12px;\n  border: 1px solid #ccc;\n  border-radius: 3px;\n}\n\nlabel {\n  margin-bottom: 10px;\n  display: block;\n}\n\n.icon-container {\n  margin-bottom: 20px;\n  padding: 7px 0;\n  font-size: 24px;\n}\n\n.btn {\n  background-color: #4CAF50;\n  color: white;\n  padding: 12px;\n  margin: 10px 0;\n  border: none;\n  width: 100%;\n  border-radius: 3px;\n  cursor: pointer;\n  font-size: 17px;\n}\n\n.btn:hover {\n  background-color: #45a049;\n}\n\na {\n  color: #2196F3;\n}\n\nhr {\n  border: 1px solid lightgrey;\n}\n\nspan.price {\n  float: right;\n  color: grey;\n}\n\n/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */\n@media (max-width: 800px) {\n  .row {\n    flex-direction: column-reverse;\n  }\n  .col-25 {\n    margin-bottom: 20px;\n  }\n}\n'
          }}
        />
        <Link to="/profile">
          <button className="btn-danger btn-large">Inapoi la profil</button>
        </Link>
        <h2>Finalizare comanda</h2>
        <div className="row">
          <div className="col-75">
            <div className="container">
              <form action="/action_page.php">
                <div className="row">
                  <div className="col-50">
                    <h3>Adresa facturare</h3>
                    <label htmlFor="fname">
                      <i className="fa fa-user" /> Nume Prenume
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="firstname"
                      placeholder="Vasilescu Andrei"
                    />
                    <label htmlFor="email">
                      <i className="fa fa-envelope" /> Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="vasilescu.andrei@example.com"
                    />
                    <label htmlFor="adr">
                      <i className="fa fa-address-card-o" /> Adresa
                    </label>
                    <input
                      type="text"
                      id="adr"
                      name="address"
                      placeholder="542 W. 15th Street"
                    />
                    <label htmlFor="city">
                      <i className="fa fa-institution" />
                      Judetul
                    </label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="Bucuresti"
                    />
                    <div className="row">
                      <div className="col-50">
                        <label htmlFor="state">Orasul</label>
                        <input
                          type="text"
                          id="state"
                          name="state"
                          placeholder="Bucuresti"
                        />
                      </div>
                      <div className="col-50">
                        <label htmlFor="zip">Cod postal</label>
                        <input
                          type="text"
                          id="zip"
                          name="zip"
                          placeholder={10001}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <label>
                  <input
                    type="checkbox"
                    defaultChecked="checked"
                    name="sameadr"
                  />{" "}
                  Shipping address same as billing
                </label>
                <input
                  type="submit"
                  defaultValue="Continue to checkout"
                  className="btn"
                />
              </form>
            </div>
          </div>
          <div className="col-25">
            <div className="container">
              <h4>
                Cosul{" "}
                <span className="price" style={{ color: "black" }}>
                  <i className="fa fa-shopping-cart" />{" "}
                  <b>{this.state.products.length}</b>
                </span>
              </h4>
              {this.state.products.map(product => (
                <Link to={"/configurator/item/" + product.productId}>
                  <p>
                    <img className="img-thumbnail" src={product.vizualize} />
                    <a href="#">{product.label}</a>{" "}
                    <span className="price">$15</span>
                  </p>
                </Link>
              ))}

              <hr />
              <p>
                Total{" "}
                <span className="price" style={{ color: "black" }}>
                  <b>$30</b>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;
