import React from "react";

import Rotate from "./../icons/rotation.svg";
import Resize from "./../icons/resize.svg";
import Delete from "./../icons/delete.svg";
import Up from "./../icons/up.svg";
import Down from "./../icons/down.svg";

const fabric = window.fabric;

class Text extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.addText();
    }
  }

  addText() {
    let box = new fabric.IText(this.props.text, {
      ...this.props,
      scaleX: this.props.scale,
      scaleY: this.props.scale,
      rotatingPointOffset: 20,
      borderColor: "tomato",
      cornerColor: "tomato",
      cornerSize: 10,
      padding: 20,
      transparentCorners: false,
      cornerStyle: "circle",
      borderDashArray: [3, 3],
      initialRotateValue: 0,
      hasControls: true
    });
    box.customiseCornerIcons(
      {
        settings: {
          cornerSize: 30
        },
        mtr: {
          icon: Rotate,
          cursor: null
        },
        br: {
          icon: Resize,
          cursor: null
        },
        bl: {
          icon: Delete,
          cursor: null
        }
      },
      () => {
        this.props.canvas.renderAll();
      }
    );
    this.props.canvas.add(box);
  }

  componentDidMount() {
    this.addText();
  }

  render() {
    return null;
  }
}

export default Text;
