import React from 'react'
import PropTypes from 'prop-types'
import Rotate from "./../icons/rotation.svg"
import Resize from "./../icons/resize.svg"
import Delete from "./../icons/delete.svg"

const fabric = window.fabric

class Image extends React.Component {
    static lockImageProps = {
        lockMovementX: true,
        lockMovementY: true,
        hasControls: false,
        hasBorders: false,
        evented: false,
        selectable: false
    }
    static propTypes = {
        canvas: PropTypes.object,
        url: PropTypes.string.isRequired,
        scale: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        cornerStyle: PropTypes.string,

    }

    static defaultProps = {
        scale: 0.1,
        rotatingPointOffset: 20,
        borderColor: "gray",
        cornerColor: "gray",
        cornerSize: 10,
        transparentCorners: false,
        cornerStyle: 'circle',
        borderDashArray: [20, 20],
        initialRotateValue: 0,
        hasControls: true,
        padding: 10
    }
    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id &&
            !this.props.canvas.getObjects().some(element => element.id === this.props.id)) {
            this.addImage();
        }
    }

    addImage() {
        var canvas = this.props.canvas;
        fabric.Image.fromURL(this.props.url, img => {
            //Scale image to specific canvas or default defined
            let maxDim = img.width > img.height ? img.width : img.height;
            let normalScale = canvas.getWidth() / maxDim * 0.2;
            img.scale(this.props.scale || normalScale)
                //Add image to canvas
            img.crossOrigin = "Anonymous"
            img.customiseCornerIcons({
                settings: {
                    cornerSize: 30,
                    padding: 20,
                },
                mtr: {
                    icon: Rotate,
                    cursor: null
                },
                br: {
                    icon: Resize,
                    cursor: null
                },
                bl: {
                    icon: Delete,
                    cursor: null
                },
            }, () => {
                canvas.renderAll();
            })
            this.props.canvas.add(img)
            this.props.canvas.renderAll();
        }, {
            id: this.props.id,
            elementId: this.props.elementId,
            url: this.props.url,
            angle: this.props.angle,
            top: this.props.top,
            left: this.props.left,
            borderScaleFactor: 3,
            padding: Image.defaultProps.padding,
            // lockUniScaling: true,
            hasControls: Image.defaultProps.hasControls,
            defaultScale: this.props.defaultScale || Image.defaultProps.scale,
            rotatingPointOffset: this.props.rotatingPointOffset || Image.defaultProps.rotatingPointOffset,
            borderColor: this.props.borderColor || Image.defaultProps.borderColor,
            cornerColor: this.props.cornerColor || Image.defaultProps.cornerColor,
            cornerSize: this.props.cornerSize || Image.defaultProps.cornerSize,
            transparentCorners: this.props.transparentCorners || Image.defaultProps.transparentCorners,
            cornerStyle: this.props.cornerStyle || Image.defaultProps.cornerStyle,
            // borderDashArray: this.props.borderDashArray || Image.defaultProps.borderDashArray,
            originX: "center",
            originY: "center",
            crossOrigin: 'Anonymous',
            type: "Image"
        }, {})
    }

    componentDidMount() {
        if (!this.props.canvas.getObjects().some(element => element.id === this.props.id)) {
            this.addImage();
        }
    }

    render() {
        return null
    }
}

export default Image